// import Vue from 'vue'


// import App from './App.vue'
// import WebPage from './WebPage.vue'

// import vuetify from './plugins/vuetify';



// import { router } from './router/routerApp'
// import store from './store'


// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies , { expires: '1d'})

// Vue.config.productionTip = false


// window.App = new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App),
// }).$mount('#app')









import Vue from 'vue'
import vuetify from './plugins/vuetify';
import VueGtm from '@gtm-support/vue2-gtm';

import "/node_modules/flag-icons/css/flag-icons.min.css";

Vue.config.productionTip = false

let targetEnv = process.env.VUE_APP_MODE || 'notwebpage'

if (process.env.VUE_APP_DEV) {
    targetEnv = 'notwebpage'
}



let importComponent = () => {
  if (targetEnv=='webpage') {
      return import('./WebPage.vue')
  } else {
      return import('./App.vue')
  }
}

let importRouter = () => {
  if (targetEnv=='webpage') {
      return import('./router/routerWebsite.js')
  } else {
      return import('./router/routerApp.js')
  }
}


let importStore = () => {
  if (targetEnv=='webpage') {
      return null
  } else {
      return import('./store')
  }
}


let importCookies = () => {
  if (targetEnv=='webpage') {
      return null
  } else {
      return import('vue-cookies')
  }
}



let gtmData = {
  id: 'GTM-K6GJX67', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']


  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)

}




async function launch() {
  if (targetEnv=='webpage') {
    // launch app
  
    let router = await importRouter()
    let component = await importComponent()

    // console.log(router)
    // console.log(component)

    Vue.use(VueGtm, gtmData)

    window.App = new Vue({
      router: router.router,
      vuetify,
      render: h => h(component.default),
    }).$mount('#app')
  
  } else {
  
    let router =  await importRouter()
    let component = await importComponent()
    let store = await importStore()
    let cookies = await importCookies()
    window.cookies = cookies

    // console.log(router)
    // console.log(component)
    // console.log(store)
    // console.log(cookies)
  
    Vue.use(cookies.default , { expires: '1d'})
    Vue.use(VueGtm, gtmData)
  
    window.App = new Vue({
      router: router.router,
      store: store.default,
      vuetify,
      render: h => h(component.default),
    }).$mount('#app')
  }
}

launch()
