import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


import theme from '@/lib/theme';
export default new Vuetify({
    theme: {
        // dark: false,
        options: {
          customProperties: true, // lets me use eg primary color as variable in css var(--v-primary-base)
        },
        themes: {
          light: theme.colors,
        },
      },
});

