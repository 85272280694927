import colors from 'vuetify/lib/util/colors'

export default {
    logos : {
        large : require('@/assets/logos/neuralBet_logo-transparent.png'),
        small : require('@/assets/logos/neuralBet_logo-transparent.png')
    },

    colors : {
        primary: {
            base :'#1C0732',
            lighten1 : '#4f4299'
        },
        secondary: '#52BD94',
        accent: '#F2F1F3',
        // error: '#FF5252',
        info: '#4f4299',
        // success: '#4CAF50',
        // warning: '#FFC107',
        primary_light : '#4f4299',






    }


//   primary: {
//     base: colors.yellow.base,
//     darken1: colors.purple.darken2,
//   },
//   secondary: colors.indigo,
//   // All keys will generate theme styles,
//   // Here we add a custom `tertiary` color
//   nb_test: colors.pink.base,




    

}